<template>
  <v-card>
    <v-toolbar flat color="#fff">
      <v-col cols="3" class="pl-0" style="height:64px;">
        <v-text-field
          label="Filter items"
          prepend-inner-icon="mdi-filter-outline"
          outlined
          dense
          background-color="#fff"
          v-model="filter"
          clearable>
        </v-text-field>
      </v-col>
       <v-spacer></v-spacer>
       <div>
        <v-btn
          v-show="id && !readOnlyPromo"
          id="newGroupButton"
          small
          class="ml-2"
          color='primary'
          dark
          @click="createNewGroup">
          New Group
        </v-btn>
       </div>
    </v-toolbar>
    <v-divider/>
    <v-data-table
      :height="tableSize"
      :loading="loading"
      :headers="headers"
      :expanded="expanded"
      :items.sync="items"
      :items-per-page="-1"
      :footer-props="{'items-per-page-options': [-1]}"
      color="#fff"
      loading-text="Loading... Please wait"
      :no-data-text="noDataText"
      fixed-header
      dense
      group-by="promo_item_group.id"
      :search="filter">

      <template v-slot:no-data>
        <v-alert
          v-if="!loading && items.length === 0"
          outlined
          text
          color="orange"
          class="mt-4">
          <template v-slot:default>
            <v-icon color="orange" class="mx-2 pb-1">
              mdi-alert-circle-outline
            </v-icon>
            <span v-if="itemGroupList.length === 0">
              Please create an item group to add items to this promotion.
            </span>
            <span v-else>
              Valid promotion requires at least one item added to promotion.
            </span>
          </template>
        </v-alert>
      </template>

       <template v-slot:[`header.variance`]="{ header }">
        <span v-if="promo.enforce_compliance">{{header.text}}</span>
      </template>

      <!-- Promo Item Group Header/Actions -->
      <template v-slot:[`group.header`]="{ group, items, headers }">
        <td colspan="3">{{items[0].promo_item_group.name}}</td>
        <td :colspan="headers.length - 5">
          <em>
            {{ (groupOverridingPromo(items[0].promo_item_group)) ? 'Group values are overriding promo values': 'Values set by promo' }}
          </em>
        </td>
        <td colspan="2">
          <div class="group-actions-container">
            <v-btn
              icon
              small
              @click.stop="editGroup(group)">
              <v-icon v-if="!readOnlyPromo">mdi-pencil-outline</v-icon>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-variant-circle-outline</v-icon>
                </template>
                View Group Details
              </v-tooltip>
            </v-btn>
            <v-btn
              v-show="!readOnlyPromo"
              icon
              small
              class="ml-1"
              @click.stop="deletedPromoItemGroup(group, items[0].promo_item_group.name)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </td>
      </template>

      <!-- Edit Qty Per Case -->
      <template v-slot:[`item.item.qty_per_case`]="{ item }">
        <span v-if="!usePromoItemQtyPerCase">
          <span>
            {{ item.item.qty_per_case }}
          </span>
        </span>
        <v-menu
          v-else
          v-model="editingQtyPerCase"
          :close-on-content-click="false"
          :close-on-click="false"
          bottom
          right
          absolute
          nudge-top="-12px"
          nudge-left="16px"
          offset-y
          max-width="300px">
          <template v-slot:activator="{ on, attrs }">
            <div class="edit-menu-container"
              :style="!readOnlyPromo ? 'cursor: pointer' : 'cursor: default'"
              v-on="!readOnlyPromo ? on : null"
              v-bind="attrs"
              @click="!readOnlyPromo ? openQtyPerCase(item) : null">
              <span>{{ item.promo_item_qty_per_case ? item.promo_item_qty_per_case : item.item.qty_per_case }}</span>
              <v-icon small v-if="!readOnlyPromo">mdi-pencil</v-icon>
            </div>
          </template>
          <v-card class="pt-2 px-4" tile flat v-if="editItem && editItem.id === item.id">
            <div style="margin-top: 8px">
              <span class="subtitle-2">Edit Qty Per Case</span>
            </div>
            <v-text-field
              ref="qty_per_case"
              class="pt-0 mt-0"
              v-model="editItem.qty_per_case"
              :rules="[isValidQuantity, v => !(v < 0) || 'Cannot have negative value.']"
              autofocus
              type="number"
              hide-spin-buttons>
            </v-text-field>
            <v-card-actions class="pt-0 px-0">
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                @click="cancel">
                Cancel
              </v-btn>
              <v-btn
                small
                color="primary"
                :loading="savingItem"
                :disabled="savingItem || applyingAll || (editItem && editItem.qty_per_case < 0)"
                @click="save">
                Save
              </v-btn>
              <v-btn
                small
                color="accent"
                :loading="applyingAll"
                :disabled="applyingAll || savingItem || (editItem && editItem.qty_per_case < 0)"
                @click="openApplyAllDialog">
                Apply All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>

      <!-- Discontinued Item Indicator -->
      <template v-slot:[`item.item.source_name`]="{ item }">
        <v-tooltip class="mx-auto" v-if="['DISCONTINUED ITEM', 'Inactive'].includes(item.item.status)" top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              small
              color="warning"
              class="pb-1 ml-n8">
              <v-icon class="mx-auto">mdi-alert</v-icon>
            </v-btn>
          </template>
          <span>Item has been Discontinued</span>
        </v-tooltip>
          {{ item.item.source_name }}
      </template>

      <template v-slot:[`item.variance`]="{ item }">
        <span v-if="item && promo.enforce_compliance">
          {{ item.variance ? item.variance : '' }}
        </span>
      </template>
      <template v-slot:[`item.buy_item`]="{ item }">
        <span v-if="item && promo_type_fields.includes('buy_item')">
          {{ item.promo_item_group ? item.promo_item_group.buy_item : '' }}
        </span>
      </template>
      <template v-slot:[`item.get_item`]="{ item }">
        <span v-if="item && promo_type_fields.includes('get_item')">
          {{ item.promo_item_group ? item.promo_item_group.get_item : '' }}
        </span>
      </template>
      <template v-slot:[`item.qty`]="{ item }">
        <span v-if="item && promo_type_fields.includes('qty')">
          {{ item.promo_item_group ? item.promo_item_group.qty : '' }}
        </span>
      </template>

      <!-- Future Cost -->
      <template v-slot:[`item.cost`]="{ item }">
        <span v-if="!usePromoItemCost">
          <v-tooltip top v-if="(item.item.future_cost == item.item.cost) && (item.item.future_cost_date <= promo.end_dt)">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="primary--text font-weight-bold">
                {{ item.item.cost }}
              </span>
            </template>
            This item has a cost change on {{ $config.formatDate(item.item.future_cost_date) }} <br>
            The new cost of {{ item.item.cost }} is being used on this promotion
          </v-tooltip>
          <span v-else>
            {{ item.item.cost }}
          </span>
        </span>
        <!-- Edit Item Cost -->
        <v-menu
          v-else
          v-model="editingCost"
          :close-on-content-click="false"
          :close-on-click="false"
          bottom
          right
          absolute
          nudge-top="-12px"
          nudge-left="16px"
          offset-y
          max-width="300px">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="edit-menu-container"
              :style="!readOnlyPromo ? 'cursor: pointer' : 'cursor: default'"
              v-on="!readOnlyPromo ? on : null"
              v-bind="attrs"
              @click="!readOnlyPromo ? open(item, 'cost') : null">
              <v-tooltip top v-if="(item.item.future_cost == item.item.cost) && (item.item.future_cost_date <= promo.end_dt)">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="primary--text font-weight-bold">
                    {{ item.cost }}
                  </span>
                </template>
                This item has a cost change on {{ $config.formatDate(item.item.future_cost_date) }} <br>
                The new cost of {{ item.cost }} is being used on this promotion
              </v-tooltip>
              <span v-else>
                {{ item.cost }}
              </span>
              <v-icon small v-if="!readOnlyPromo">mdi-pencil</v-icon>
            </div>
          </template>
          <v-card class="pt-2 px-4" tile flat v-if="editItem && editItem.id === item.id">
            <div style="margin-top: 8px">
              <span class="subtitle-2">Edit Item Cost</span>
            </div>
            <v-text-field
              ref="cost"
              class="pt-0 mt-0"
              v-model="editItem.cost"
              :rules="[isValidPrice, v => !(v < 0) || 'Cannot have negative value.']"
              autofocus
              type="number"
              hide-spin-buttons
              prefix="$">
            </v-text-field>
            <v-card-actions class="pt-0 px-0">
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                @click="cancel">
                Cancel
              </v-btn>
              <v-btn
                small
                color="primary"
                :loading="savingItem"
                :disabled="savingItem || applyingAll || (editItem && editItem.cost < 0)"
                @click="save">
                Save
              </v-btn>
              <v-btn
                small
                color="accent"
                :loading="applyingAll"
                :disabled="applyingAll || savingItem || (editItem && editItem.cost < 0)"
                @click="openApplyAllDialog">
                Apply All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>

      <!-- Edit Retail Price -->
      <template v-slot:[`item.retail_price`]="{ item }">
        <v-menu
          v-model="editingRetailPrice"
          :close-on-content-click="false"
          :close-on-click="false"
          bottom
          right
          absolute
          nudge-top="-12px"
          nudge-left="16px"
          offset-y
          max-width="300px">
          <template v-slot:activator="{ on, attrs }">
            <div class="edit-menu-container"
              :style="!readOnlyPromo ? 'cursor: pointer' : 'cursor: default'"
              v-on="!readOnlyPromo ? on : null"
              v-bind="attrs"
              @click="!readOnlyPromo ? open(item, 'retail_price') : null">
              <span>{{ item.retail_price }}</span>
              <v-icon small v-if="!readOnlyPromo">mdi-pencil</v-icon>
            </div>
          </template>
          <v-card class="pt-2 px-4" tile flat v-if="editItem && editItem.id === item.id">
            <div style="margin-top: 8px">
              <span class="subtitle-2">Edit Regular Retail</span>
            </div>
            <v-text-field
              ref="retail_price"
              class="pt-0 mt-0"
              v-model="editItem.retail_price"
              :rules="[isValidPrice, v => !(v < 0) || 'Cannot have negative value.']"
              autofocus
              type="number"
              hide-spin-buttons
              prefix="$">
            </v-text-field>
            <v-card-actions class="pt-0 px-0">
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                @click="cancel">
                Cancel
              </v-btn>
              <v-btn
                small
                color="primary"
                :loading="savingItem"
                :disabled="savingItem || applyingAll || (editItem && editItem.retail_price < 0)"
                @click="save">
                Save
              </v-btn>
              <v-btn
                small
                color="accent"
                :loading="applyingAll"
                :disabled="applyingAll || savingItem || (editItem && editItem.retail_price < 0)"
                @click="openApplyAllDialog">
                Apply All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>

       <!-- Accrual Amount -->
       <template v-slot:[`item.billback_allowance`]="{ item }">
        <v-menu
          v-if="(item.billback_allowance != null) && item.accrual_amt"
          open-on-hover
          offset-y
          top
          :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <span style="display: inline-block; margin-right: 4px">
              {{ item.billback_allowance }}
            </span>
            <v-btn
              class="pa-0 ma-0"
              v-on="on"
              small
              icon>
              <v-icon class="ma-0 pa-0">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Allowance Amount</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ getFormattedAllowance(item.promo_item_group) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Allowance Type</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.promo_item_group.allowance_amt_type }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Accrual Amount</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ `$${$config.formatCurrency(item.accrual_amt)}`}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <span v-else>{{ item.billback_allowance }}</span>
      </template>

      <!-- AMAP Allowance Menu -->
      <template v-slot:[`item.amap_allowance`]="{ item}">
        <v-menu
          v-if="!isAMAP && Array.isArray(item.item.amap_allowances)"
          :nudge-left="250"
          offset-y
          open-on-hover
          top>
          <template v-slot:activator="{ on }">
            <span class="primary--text" v-on="on" style="cursor: pointer">
              {{ item.amap_allowance_total }}
            </span>
          </template>
          <v-card>
            <v-card-title class="pl-6 primary--text">
              Active AMAP Allowances
            </v-card-title>
            <v-list class="pt-0">
              <v-list-item>
                <v-col cols="5">
                  <v-list-item-subtitle class="font-weight-bold">
                    Promo Name
                  </v-list-item-subtitle>
                </v-col>
                <v-col cols="5">
                  <v-list-item-subtitle class="font-weight-bold">
                    Promo Number
                  </v-list-item-subtitle>
                </v-col>
                <v-col cols="2">
                  <v-list-item-subtitle class="font-weight-bold">
                    Amount
                  </v-list-item-subtitle>
                </v-col>
              </v-list-item>
              <v-list-item
                dense
                v-for="amap in item.item.amap_allowances"
                :key="amap.promo_id"
                link
                @click="goToPromo(amap.promo_id)">
                <v-col cols="5">
                  <v-list-item-subtitle>{{ amap.promo_name }}</v-list-item-subtitle>
                </v-col>
                <v-col cols="5">
                  <v-list-item-subtitle>{{ amap.promo_number }}</v-list-item-subtitle>
                </v-col>
                <v-col cols="2">
                  <v-list-item-subtitle>
                    ${{ $config.formatCurrency(amap.amap_allowance) }}
                  </v-list-item-subtitle>
                </v-col>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <span v-else>{{ item.amap_allowance }}</span>
      </template>

      <!-- Active Allowances -->
      <template v-slot:[`item.net_unit_cost`]="{ item }">
        <v-menu
          v-if="item.has_active_allowances"
          :nudge-left="250"
          offset-y
          open-on-hover
          top>
          <template v-slot:activator="{ on }">
            <span class="primary--text" v-on="on" style="cursor: pointer">
              {{ item.net_unit_cost }}
            </span>
          </template>
          <ActiveAllowances
            :item="item.item"
            :allowances="item.item.filtered_allowances"
            @goToPromo="goToPromo">
          </ActiveAllowances>
        </v-menu>
        <span v-else>{{ item.net_unit_cost }}</span>
      </template>

      <!-- Related Items -->
      <template v-slot:[`item.related`]="{ item }">
        <v-tooltip class="mx-auto" v-if="item.item.has_item_group" top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              small
              color="#1976d2"
              class="px-0 mx-0"
              @click.stop="viewRelatedItems(item)">
              <v-icon class="mx-auto">mdi-view-list</v-icon>
            </v-btn>
          </template>
          <span>Related Items</span>
        </v-tooltip>
      </template>

      <!-- Remove Item -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="!readOnlyPromo">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              small
              color="red"
              @click.stop="removeItem(item)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Remove</span>
        </v-tooltip>
      </template>
    </v-data-table>

  <!-- Item Add/Search -->
    <ItemSearchModal
      v-if="addItems"
      v-model="addItems"
      :itemGroup="itemGroup"
      :panel_height="panel_height"
      :promo="promo"
      :promo_type_fields="promo_type_fields"
      :promo_type_constant="promo_type_constant"
      :promoItems="promoItems"
      :item_sources="item_sources"
      :vmc_sources="vmc_sources"
      :readOnlyPromo="readOnlyPromo"
      :vendorIds="vendorIds"
      :isAccrualPromo="isAccrualPromo"
      @close="closeItemsModal"
      @updateHistory="$emit('updateHistory')">
    </ItemSearchModal>
    <RelatedItemsModal
      v-if="showRelatedItemsModal"
      v-model="showRelatedItemsModal"
      :promoItem="promoItem"
      :promoItems="promoItems"
      :promoItemGroup="promoItem ? promoItem.promo_item_group : null"
      :item_sources="item_sources"
      :vendorIds="vendorIds"
      :readOnlyPromo="readOnlyPromo"
      :promo="promo"
      @close="closeRelatedItemsModal"
      @cancel="showRelatedItemsModal = false">
    </RelatedItemsModal>
    <DeleteItemGroupModal
      @close="deleteModalData.deleting = false"
      v-if="deleteModalData.deleting"
      :groupInfo="deleteModalData"
      @update="deletedPromoItemGroup">
    </DeleteItemGroupModal>

    <!-- Edit Item 'Apply All' Confirmation -->
    <v-dialog
      :value="applyAllDialog"
      width="450"
      @click:outside="closeApplyAllDialog">
      <v-card>
        <v-card-title>
          Confirm Apply All
        </v-card-title>
        <v-card-text>
          {{ applyAllText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey darken-1" text @click="closeApplyAllDialog">
            Cancel
          </v-btn>
          <v-btn
            color="success"
            :loading="applyingAll"
            :disabled="applyingAll"
            @click="applyAll">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
// API
import PromoItem from '@/axios/promotion-item-endpoint'
import PromoItemGroup from '@/axios/promotion-item-group-endpoint'

// Components
const RelatedItemsModal = () => import('@/components/items/RelatedItemsModal.vue')
const ItemSearchModal = () => import('@/components/items/ItemSearchModal.vue')
const DeleteItemGroupModal = () => import('@/components/items/DeleteItemGroupModal.vue')
const ActiveAllowances = () => import('@/components/items/ActiveAllowances.vue')
// Mixins
import { fullWidthTable } from '@/mixins/table'
import { displayAlert } from '@/mixins/alert'
import { formValidation } from '@/mixins/form-validation'
import { utils } from '@/mixins/utils'
import { userAccess } from '@/mixins/user-access'

// Third Party Helpers
import { cloneDeep } from 'lodash'
export default {
  name: 'items-on-promo',
  data () {
    return {
      deleteModalData: {
        deleting: false,
        groupId: '',
        groupName: ''
      },
      from: 0,
      addItems: false,
      editingCost: false,
      editingRetailPrice: false,
      editingQtyPerCase: false,
      savingItem: false,
      applyingAll: false,
      expanded: [],
      filter: '',
      itemGroup: null,
      editItem: null,
      editKey: '',
      itemGroupList: [],
      items: [],
      promoItems: [],
      search:'',
      valid: true,
      showRelatedItemsModal: false,
      applyAllDialog: false,
      promoItem: null,
      formatFields: [
        'cost', 'retail_price', 'promotion_price', 'amount_off',
        'rebate_amount', 'billback_allowance', 'amap_allowance', 'early_buy_allowance', 'variance', 'accrual_allowance'
      ],
      costStackConfig: {
        EDLC: ['EDLC', 'AMAP', 'EBA'],
        EBA: [],
        AMAP: [],
        TPR: ['TPR', 'EDLC', 'AMAP', 'EBA'],
        AD: ['AD', 'TPR', 'EDLC', 'AMAP', 'EBA']
      }
    }
  },
  components: { DeleteItemGroupModal, ItemSearchModal, RelatedItemsModal, ActiveAllowances },
  mixins: [
    fullWidthTable,
    displayAlert,
    formValidation,
    utils,
    userAccess
  ],
  props: {
    item_sources: Array,
    vmc_sources: Array,
    panel_height: Number,
    promo: Object,
    promo_type_constant: String,
    vendorIds: Array,
    readOnlyPromo: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    filter: {
      handler () {
        if (this.filter === null) {
          this.filter = ''
        }
      }
    },
    item_sources: {
      handler (newValue, oldValue) {
        if (this.$route.query?.id && newValue) {
          if (!oldValue || (JSON.stringify(newValue) !== JSON.stringify(oldValue))) {
            this.initPromoItemList()
          }
        }
      },
      deep: true
    },
    vendorIds: {
      handler (newValue, oldValue) {
        if (this.$route.query?.id && newValue) {
          if (!oldValue || (JSON.stringify(newValue) !== JSON.stringify(oldValue))) {
            this.initPromoItemList()
          }
        }
      },
      deep: true
    },
    panel_height: {
      handler (value) {
        this.nonTableHeight = value
      }
    },
    'promo.audit.updated_on': {
      handler (newVal) {
        if (newVal) {
          this.initPromoItemList()
          this.initItemGroups()
        }
      }
    },
    '$route.query.id': {
      handler (newValue, oldValue) {
        if (newValue && oldValue) {
          this.updateLists()
        }
      }
    }
  },
  computed: {
    startHeaders() {
      return [
        { sortable: false, filterable: false, width: '1%' },
        { text: 'Source', sortable: false, value: 'item.source_name' },
        { text: 'Item ID', sortable: false, value: 'item.item_id', width: '5%' },
        { text: 'Qty Per Case', sortable: false, value: 'item.qty_per_case', width: '5%' },
        { text: 'Pack Size', sortable: false, value: 'item.pack_size' },
        { text: 'Description', sortable: false, value: 'item.description' },
        { text: 'UPC', sortable: false, value: 'item.upc' },
        { text: 'Department', sortable: false, value: 'item.department' },
        { text: 'Case Cost', sortable: false, value: 'cost' },
      ];
    },
    endHeaders() {
      return [
        { text: 'Net Unit Cost', sortable: false, value: 'net_unit_cost', width: '5%' },
        { text: 'Regular Retail', sortable: false, value: 'retail_price' },
        { text: 'Gross Profit %', sortable: false, value: 'gross_profit' },
        { text: 'Related Items', value: 'related', sortable: false, filterable: 'false', align: 'center' },
        { text: '', value: 'actions', sortable: false, filterable: false, width: '2%' },
      ];
    },
    promoTypeHeaders () {
    return [
        // {text: 'Amount Off', sortable: false, value: 'amount_off'},
        { text: 'Billback Allowance', sortable: false, value: 'billback_allowance' },
        { text: 'AMAP Allowance', sortable: false, value: 'amap_allowance' },
        { text: 'Early Buy Allowance', sortable: false, value: 'early_buy_allowance' },
        { text: this.tenant === 'pricechopper' && ['BUY_X_SAVE_$Y', 'BUY_X_GET_X'].includes(this.promo_type_constant) ? 'Redemption Allowance' : 'Scan Allowance', sortable: false, value: 'rebate_amount' },
        { text: 'Promotion Price', sortable: false, value: 'promotion_price' },
        { text: 'Variance', sortable: false, value: 'variance' },
        { text: 'Buy Item', sortable: false, value: 'buy_item' },
        { text: 'Get Item', sortable: false, value: 'get_item' },
        { text: 'Quantity', sortable: false, value: 'qty' },
      ]
    },
    existingPromo () {
      return (this.$route.query.id) ?? false
    },
    headers() {
      let finalHeaders = [...this.startHeaders, ...this.currentTypeHeaders, ...this.endHeaders]
      if (this.tenant === 'pricechopper') {
        const index = finalHeaders.findIndex(header => header.text === 'UPC')
        finalHeaders.splice(index + 1, 0, { text: 'GTIN', sortable: false, value: 'item.gtin' })
        if (!this.isEBA) finalHeaders = finalHeaders.filter(h => h.value !== 'early_buy_allowance')
      }
      if (this.tenant === 'alliance-retail-group' && this.promo.promo_category?.name === 'Ad Scan') {
        const index = finalHeaders.findIndex(header => header.text === 'Scan Allowance')
        finalHeaders.splice(index + 1, 0, { text: 'Accrual Allowance', sortable: false, value: 'accrual_allowance' })
      }
      finalHeaders = finalHeaders.map(header => ({ ...header, class: 'accent white--text' }))
      return finalHeaders
    },
    currentTypeHeaders() {
      return this.promoTypeHeaders.filter(header => {
        return this.promo_type_fields.includes(header.value)
      })
    },
    id () {
      return (this.$route.query.id) ?? this.promo_id
    },
    noDataText () {
      return (this.$route.query?.id)
        ? 'Please add items to the promotion'
        : 'Please finish creating promotion to add items'
    },
    promo_id (){
      return this.promo.id
    },
    promo_type_fields () {
      let fields = []
      switch (this.promo_type_constant) {
        case ('BUY_X_GET_X'):
          fields = [
            'buy_item',
            'get_item',
            'qty',
            'rebate_amount',
          ]
          if (this.tenant === 'pricechopper') fields.push('promotion_price')
          if (this.promo.enforce_compliance && !this.tenantIgnoresCompliance) fields.push('variance')
          return fields
        case ('BUY_X_SAVE_$Y'):
          fields = [
            'qty',
            'rebate_amount'
          ]
          if (this.tenant === 'pricechopper') fields.push('promotion_price')
          if (this.promo.enforce_compliance && !this.tenantIgnoresCompliance) fields.push('variance')
          return fields
        case ('AMOUNT_OFF'):
          fields = [
            'amount_off',
            'promotion_price',
            'rebate_amount',
            'retail_price'
          ]
          if (this.isNonAllowancePromo) {
            fields = ['promotion_price', 'retail_price']
          }
          if (this.promo.enforce_compliance && !this.tenantIgnoresCompliance) fields.push('variance')
          return fields
        case ('PERCENT_OFF'):
          fields = [
            'percent_off',
            'promotion_price',
            'rebate_amount',
            'retail_price'
          ]
          if (this.promo.enforce_compliance && !this.tenantIgnoresCompliance) fields.push('variance')
          return fields
        case ('BILLBACK'):
          if (this.isAMAP) {
            fields = ['amap_allowance', 'promotion_price']
          } else if (this.isEBA) {
            fields = ['early_buy_allowance', 'promotion_price']
          } else if (this.isAccrualPromo) {
            fields = ['billback_allowance', 'promotion_price']
          } else if (this.isNonAllowancePromo) {
            fields = ['promotion_price']
          } else {
            fields = [
                'billback_allowance',
                'amap_allowance',
                'early_buy_allowance',
                'promotion_price'
              ]
          }
          return fields
        default:
          return fields
      }
    },
    isActiveAllowanceTenant () {
      return ['pricechopper', 'alliance-retail-group'].includes(this.$auth.tenant)
    },
    isScanPromo () {
      return this.promo?.promo_category?.scan_promotion
        && !this.isComplexPromo
    },
    isComplexPromo () {
      return ['BUY_X_GET_X', 'BUY_X_SAVE_$Y'].includes(this.promo_type_constant)
    },
    usePromoItemCost () {
      return this.tenant === 'pricechopper' && this.promo?.promo_category?.name.toLowerCase().includes('dsd')
    },
    usePromoItemQtyPerCase () {
      return this.tenant === 'pricechopper' && this.promo?.promo_category?.name.toLowerCase().includes('dsd')
    },
    allowanceFields () {
      return this.promo?.promo_category?.scan_promotion
        ? ['rebate_amount']
        : ['billback_allowance', 'amap_allowance', 'early_buy_allowance', 'amap_allowance_total']
    },
    scanAllowanceFields () {
      return ['rebate_amount', 'ad_scan', 'edlc_scan', 'tpr_scan']
    },
    billbackAllowanceFields () {
      return ['billback_allowance', 'amap_allowance', 'early_buy_allowance', 'ad_billback', 'edlc_billback', 'tpr_billback']
    },
    applyAllText () {
      if (this.applyAllDialog) {
        const newValue = this.editItem?.[this.editKey]
        if (newValue == null || newValue === '') {
          return `Clear edited value for all items in the group?`
        }
        const formattedValue = this.getFormattedPrice(newValue)
        if (this.editingCost) {
          return `Apply case cost of ${formattedValue} to all items in the group?`
        }
        if (this.editingQtyPerCase) {
          return `Apply qty per case of ${newValue} to all items in the group?`
        }
        if (this.editingRetailPrice) {
          return `Apply retail price of ${formattedValue} to all items in the group?`
        }
      }
      return null
    }
  },
  created () {
    this.nonTableHeight = this.panel_height
    this.initPromoItemList()
    this.initItemGroups()
  },
  methods: {
    goToPromo(promo_id) {
      let route = this.$router.resolve({ path: "/promo", query: { id: promo_id } })
      window.open(route.href)
    },
    closeItemsModal (updateItems = false) {
      this.addItems = false
      this.itemGroup = null
      if (updateItems) this.updateLists()
    },
    closeRelatedItemsModal (updateItems = false) {
      this.showRelatedItemsModal = false
      if (updateItems) {
        this.$emit('updateHistory')
        this.updateLists()
      }
    },
    open (item, key) {
      this.editKey = key
      this.editItem = cloneDeep(item)
      const numericValue = this.getNumericPrice(item[key])
      const editValue = (numericValue === 0) ? null : numericValue.toFixed(2)
      this.editItem[key] = editValue
    },
    openQtyPerCase (item) {
      this.editKey = 'qty_per_case'
      this.editItem = cloneDeep(item)
      let numericValue
      if (this.usePromoItemQtyPerCase && item['promo_item_qty_per_case']) {
        numericValue = item['promo_item_qty_per_case']
      } else {
        numericValue = item.item['qty_per_case']
      }
      this.editItem['qty_per_case'] = numericValue
    },
    save () {
      const valid = this.$refs[this.editKey].validate()
      if (valid) {
        return this.updateItem(this.editItem)
      }
    },
    cancel () {
      this.editItem = null
      this.editingCost = false
      this.editingRetailPrice = false
      this.editingQtyPerCase = false
      this.editKey = ''
    },
    openApplyAllDialog () {
      const valid = this.$refs[this.editKey].validate()
      if (valid) {
        this.applyAllDialog = true
      }
    },
    closeApplyAllDialog () {
      this.applyAllDialog = false
    },
    async applyAll () {
      const groupItems = this.items.filter(item => item.promo_item_group.id === this.editItem.promo_item_group.id)
      this.applyingAll = true
      try {
        const promises = groupItems.map(item => {
          const promoItem = cloneDeep(this.promoItems.find(pi => pi.id === item.id))
          promoItem[this.editKey] = this.editItem[this.editKey]
          return this.updateItem(promoItem, true)
        })
        const { rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
        this.cancel()
        this.updateLists()
        this.$emit('updateHistory')
        this.closeApplyAllDialog()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.applyingAll = false
      }
    },
    deletedPromoItemGroup (group, groupName) {
      if (group) {
        this.deleteModalData.deleting = true
        this.deleteModalData.groupId = group
        this.deleteModalData.groupName = groupName
      } else {
        this.updateLists()
        this.$emit('updateHistory')
      }
    },
    async initPromoItemList () {
      this.loading = true
      const promoId = this.$route.query.id
      let items = []
      try {
        const res = await PromoItem.getItemsByPromo({
          promo_id: promoId,
          offset: 0,
          limit: 200,
          paging: true,
          check_allowances: this.isActiveAllowanceTenant
        })
        this.promoItems = res?.data || []
        if (res?.data?.length > 0) {
          items = res.data.map(promoItem => this.formatPromoItem(promoItem))
        }
        this.items = items
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    formatPromoItem (promoItem) {
      for (const field of this.formatFields) {
        promoItem[field] = this.getFormattedDisplayValue(promoItem, field)
      }
      promoItem.item = this.formatItemPrices(promoItem.item)
      if (this.isAccrualPromo) {
        if (promoItem.accrual_amt != null) {
          promoItem.billback_allowance = this.getFormattedPrice(promoItem.accrual_amt)
        }
      }
      return this.handleItemCalc(promoItem)
    },
    getFilteredAMAPs (item) {
      const filtered = item.amap_allowances.filter(amap => {
        // not ideal but only way to distinguish these promos currently
        return amap?.promo_name?.toLowerCase()?.includes('associated wholesale grocers')
      })
      return (filtered.length > 0) ? filtered : null
    },
    getFormattedDisplayValue (promoItem, key) {
      const group = promoItem.promo_item_group
      let value = promoItem[key] || group[key]
      if (value == null && this.promo[key]) {
        value = this.promo[key]
      }
      if (value == null && key === 'retail_price' && promoItem.item.sale_price !== null) {
        value = promoItem.item.sale_price
      }
      if (value == null && key === 'cost' && promoItem.item.cost !== null) {
        value = promoItem.item.cost
      }
      return this.getFormattedPrice(value)
    },
    handleItemCalc (promoItem) {
      if (!this.isScanPromo && !this.isAMAP && Array.isArray(promoItem.item.amap_allowances)) {
        const filteredAMAPs = this.getFilteredAMAPs(promoItem.item)
        if (filteredAMAPs) {
          const totalAMAPs = this.calcTotalAMAPs(filteredAMAPs)
          promoItem.amap_allowance_total = `$${this.$config.formatCurrency(totalAMAPs)}`
        }
        promoItem.item.amap_allowances = filteredAMAPs
      }
      const validAllowances = this.getValidActiveAllowances(promoItem)
      promoItem.has_active_allowances = (validAllowances.length > 0)
      if (promoItem.has_active_allowances) {
        promoItem.item.filtered_allowances = validAllowances
      }
      const netUnitCost = this.calcItemNetUnitCost(promoItem)
      const grossProfit = this.calcGrossProfit(promoItem, netUnitCost)

      promoItem.net_unit_cost = netUnitCost
      promoItem.gross_profit = grossProfit
      return promoItem
    },
    getValidActiveAllowances (promoItem) {
      if (promoItem?.item?.active_allowances) {
        const { active_allowances } = promoItem.item
        return active_allowances.filter(a => {
          return (a.promo_id !== this.promo.id)
            && !['CANCELLED', 'REJECTED'].includes(a.status_constant)
            && this.costStackConfig[this.promo.billing_mode.constant].includes(a.billing_mode)
            && (this.isComplexPromo || ['BUY_X_GET_X', 'BUY_X_SAVE_$Y'].includes(a.promo_type) === false)
        })
      }
      return []
    },
    calcItemNetUnitCost (promoItem) {
      let sumScans = this.calcSumAllowances(promoItem, this.scanAllowanceFields)
      let sumBillbacks = this.calcSumAllowances(promoItem, this.billbackAllowanceFields)
      if (this.$auth.tenant === 'pricechopper' && this.isComplexPromo) {
        const groupQty = promoItem.promo_item_group.qty
        const complexSumScans = (sumScans / groupQty)
        sumScans = complexSumScans
      }

      if (promoItem?.has_active_allowances) {
        const validAllowances = promoItem.item.filtered_allowances
        const allowanceTotals = this.calcSumActiveAllowances(validAllowances)
        const sumScansActive = allowanceTotals.sumScans
        const sumBillbacksActive = allowanceTotals.sumBillbacks
        sumScans = (sumScans + sumScansActive)
        sumBillbacks = (sumBillbacks + sumBillbacksActive)
      }
      const qty_per_case = this.usePromoItemQtyPerCase 
        ? promoItem.promo_item_qty_per_case || promoItem.item.qty_per_case
        : promoItem.item.qty_per_case
      return this.calcNetUnitCost(promoItem.cost, qty_per_case, sumScans, sumBillbacks)
    },
    calcSumAllowances (allowanceItem, allowanceFields) {
      const allowanceValues = allowanceFields.flatMap(field => {
        const value = allowanceItem[field]
        return value ? this.getNumericPrice(value) : []
      })
      const sum = allowanceValues.reduce((total, value) => {
        return total + value
      }, 0)
      return sum
    },
    calcSumActiveAllowances (activeAllowances) {
      const sumScans = activeAllowances.reduce((total, allowance) => {
        const value = this.calcSumAllowances(allowance, this.scanAllowanceFields)
        return total + value
      }, 0)
      const sumBillbacks = activeAllowances.reduce((total, allowance) => {
        const value = this.calcSumAllowances(allowance, this.billbackAllowanceFields)
        return total + value
      }, 0)
      return { sumScans, sumBillbacks }
    },
    calcTotalAMAPs (amaps) {
      const allowances = amaps.map(amap => amap.amap_allowance)
      return allowances.reduce((total, value) => {
        return total + value
      }, 0)
    },
    getFormattedAllowance (group) {
      const { allowance_amt, allowance_amt_type } = group
      if (allowance_amt_type?.toLowerCase() === 'percent') {
        return `${allowance_amt}%`
      } else if (allowance_amt_type?.toLowerCase() === 'per pound') {
        return `${allowance_amt} lbs`
      }
      return `$${this.$config.formatCurrency(allowance_amt)}`
    },
    viewRelatedItems (item) {
      this.promoItem = item
      this.showRelatedItemsModal = true
    },
    async addItem (item, include = true, itemGroupId = null) {
      let newItem = cloneDeep(item)
      const itemData = {
        promo_item_group_id: itemGroupId || this.itemGroup.id,
        item_id: newItem.item_id,
        include_item: include,
        source: newItem.source
      }
      await PromoItem.post(itemData).then(() => {
        if (this.bulkSearchPayload) this.updateLists()
        this.$emit('updateHistory')
      })
        .catch(err => {
          this.handleError(err)
        })
    },
    async updateItem (promoItem, applyAll = false) {
      const { item, promo_item_group } = promoItem
      let payload = {
        id: promoItem.id,
        promo_item_group_id: promo_item_group.id,
        item_id: item.item_id,
        include_item: promoItem.include_item,
        variance: promoItem.variance ? this.getNumericPrice(promoItem.variance) : null,
        source: item.source,
        retail_price: promoItem.retail_price ? this.getNumericPrice(promoItem.retail_price) : null
      }
      if (this.usePromoItemCost) {
        payload = {...payload, cost: promoItem.cost ? this.getNumericPrice(promoItem.cost) : null }
      }
      if (this.usePromoItemQtyPerCase) {
        const qty = promoItem.qty_per_case ? promoItem.qty_per_case : promoItem.promo_item_qty_per_case
        payload = {...payload, qty_per_case: Number(qty) }
      }
      this.savingItem = true
      try {
        const res = await PromoItem.put(promoItem.id, payload)
        if (res?.data?.updated === 1 && !applyAll) {
          this.editingCost = false
          this.editingRetailPrice = false
          this.editingQtyPerCase = false
          this.editItem = null
          this.updateLists()
          this.$emit('updateHistory')
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.savingItem = false
      }
    },
    async removeItem (item) {
      try {
        await PromoItem.delete(item.id)
        this.$emit('updateHistory')
        this.updateLists()
      } catch (err) {
        this.handleError(err)
      }
    },
    async initItemGroups () {
      let itemGroups = []
      const res = await PromoItemGroup.getItemGroup(this.$route.query.id)
      if (res?.data?.length > 0) {
        itemGroups = res.data.map(group => this.getFormattedGroup(group, this.isAccrualPromo))
      }
      this.itemGroupList = itemGroups
    },
    groupOverridingPromo (group) {
      const override = this.promo_type_fields.some(field => {
        const groupValue = group[`${field}`]
        const promoValue = this.promo[`${field}`]
        return (groupValue != null && groupValue != promoValue)
      })
      return override
    },
    createNewGroup () {
      this.itemGroup = null
      this.addItems = true
    },
    editGroup(groupId) {
      this.itemGroup = this.itemGroupList.find(group => group.id === groupId)
      this.addItems = true
    },
    updateLists () {
      this.initPromoItemList()
      this.initItemGroups()
    }
  }
}
</script>
<style lang="scss" scoped>
.group-actions-container {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: flex-end;
  padding: 5px 0;
  grid-column-gap: 4px;
}

.edit-menu-container {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-column-gap: 10px;
  position: relative;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: transparent;
}
</style>