<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-form ref="form" lazy-validation>
      <v-row class="promoRow">
        <v-col cols="4" class="px-1">
          <v-text-field
            v-model="promo.name"
            label="Promotion Name"
            :autofocus="!existingPromo"
            :rules="promoNameRules"
            :readonly="readOnlyPromo"
            background-color="#fff"
            outlined
            dense>
          </v-text-field>
        </v-col>
        <v-col class="px-1"
          v-for="(field, i) in currentFields"
          :cols="(currentFields.length > 3) ? null : 2"
          :key="i">
          <v-text-field
            v-if="field.isPercent"
            v-model="promo[`${field.value}`]"
            :label="field.name"
            type="number"
            min="0"
            step="1"
            suffix="%"
            :rules="[isValidPercent]"
            :disabled="readOnlyPromo"
            hide-details="auto"
            background-color="#fff"
            dense
            autocomplete="off"
            outlined>
          </v-text-field>
          <v-text-field
            v-else
            v-model="promo[`${field.value}`]"
            :label="field.name"
            type="number"
            min="0"
            step=".01"
            prefix="$"
            :rules="field.value === 'promotion_price' ? promoPriceRules : getPriceRules(field)"
            :disabled="readOnlyPromo || (field.value === 'variance' ? isVarianceDisabled : false)"
            :error="showErrorState(field, promo)"
            hide-details="auto"
            background-color="#fff"
            dense
            autocomplete="off"
            outlined>
          </v-text-field>
        </v-col>
        <v-col v-if="showAccrualAllowance" cols="2" class="px-1">
          <v-text-field
              v-model="promo['accrual_allowance']"
              label="Accrual Allowance"
              type="number"
              min="0"
              step=".01"
              prefix="$"
              :disabled="readOnlyPromo"
              hide-details="auto"
              background-color="#fff"
              dense
              autocomplete="off"
              outlined>
          </v-text-field>
        </v-col>
        <v-col v-if="tenant === 'pricechopper'" cols="2" class="px-1">
          <v-autocomplete
              v-model="promo['category_manager_id']"
              label="Category Manager"
              :items="categoryManagers"
              item-text="name"
              item-value="id"
              clearable
              :disabled="readOnlyPromo"
              hide-details="auto"
              background-color="#fff"
              dense
              autocomplete="off"
              outlined
              hide-no-data>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PromoItemGroup from '@/axios/promotion-item-group-endpoint'
import { formValidation } from '@/mixins/form-validation'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
export default {
  data () {
    return {
      billbackError: false,
      scanError: false
    }
  },
  name: 'PromoHeader',
  mixins: [formValidation, displayAlert, userAccess],
  props: {
    promo: Object,
    promo_type_constant: String,
    readOnlyPromo: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    'promo.audit.updated_on': {
      handler (newValue) {
        if (newValue) {
          this.resetErrors()
        }
      }
    }
  },
  created () {
    if (this.setZeroAllowanceValue) {
      this.promo[this.allowanceField] = 0
    }
  },
  computed: {
    fields() {
      return [
        // { name: 'Retail Price', value: 'retail_price' },
        // { name: 'Amount Off', value: 'amount_off' },
        { name: 'Percent Off', value: 'percent_off', isPercent: true },
        { name: 'Promotion Price', value: 'promotion_price' },
        { name: 'Variance', value: 'variance' },
        { name: this.tenant === 'pricechopper' && ['BUY_X_SAVE_$Y', 'BUY_X_GET_X'].includes(this.promo_type_constant) ? 'Redemption Allowance' : 'Scan Allowance', value: 'rebate_amount' },
        { name: 'Billback Allowance', value: 'billback_allowance' },
        { name: 'AMAP Allowance', value: 'amap_allowance' },
        { name: 'Early Buy Allowance', value: 'early_buy_allowance' },
        { name: this.tenant === 'pricechopper' ? 'Ad Fee' : 'Lump Sum Allowance', value: 'lump_sum_allowance' },
        { name: 'Category Manager', value: 'category_manager_id' },
        { name: 'Accrual Allowance', value: 'accrual_allowance' }
      ]
    },
    // tenant-specific logic (awg)
    isVarianceDisabled () {
      return this.tenant === 'awg' && (this.promo.variance == this.tenantDefaultVariance[this.tenant])
    },
    showLumpSum () {
      return this.tenant !== 'pricechopper' || (this.tenant === 'pricechopper' && this.promo.billing_mode?.constant === 'AD')
    },
    showAccrualAllowance() {
      return this.tenant === 'alliance-retail-group' && this.promo.promo_category?.name === 'Ad Scan'
    },
    currentFields () {
      return this.fields.filter(field => {
        return this.promo_type_fields.includes(field.value)
      })
    },
    setZeroAllowanceValue () {
      return (!this.$route.query.id && this.isNonAllowancePromo)
    },
    promoNameRules () {
      return this.tenant === 'pricechopper' ? [v => !!v || 'Promotion Name is required'] : [v => !!v || 'Promotion Name is required', this.validateText]
    },
    allowanceField () {
      return (this.promo_type_constant === 'BILLBACK')
        ? 'billback_allowance'
        : 'rebate_amount'
    },
    promo_type_fields () {
      let fields = []
      switch (this.promo_type_constant) {
        case ('BUY_X_GET_X'):
          fields = ['awg', 'pricechopper'].includes(this.tenant)
            ? ['promotion_price']
            : ['rebate_amount', 'promotion_price']
          return fields
        case ('BUY_X_SAVE_$Y'):
          fields = [
            'amount_off',
            'rebate_amount',
            'promotion_price'
          ]
          return fields
        case ('AMOUNT_OFF'):
          fields = this.isNonAllowancePromo
            ? ['promotion_price', 'lump_sum_allowance']
            : [
              'retail_price',
              'amount_off',
              'promotion_price',
              'rebate_amount'
            ]
          if (this.showLumpSum) fields.push('lump_sum_allowance')
          if (this.promo.enforce_compliance && !this.tenantIgnoresCompliance) fields.push('variance')
          return fields
        case ('PERCENT_OFF'):
          fields = [
            'retail_price',
            'percent_off',
            'promotion_price',
            'rebate_amount'
          ]
          if (this.showLumpSum) fields.push('lump_sum_allowance')
          if (this.promo.enforce_compliance && !this.tenantIgnoresCompliance) fields.push('variance')
          return fields
        case ('BILLBACK'):
          fields = ['promotion_price', 'billback_allowance']
          if (this.showLumpSum) fields.push('lump_sum_allowance')
          if (this.isAMAP) {
            fields = ['promotion_price', 'amap_allowance']
          } else if (this.isEBA) {
            fields = ['promotion_price', 'early_buy_allowance']
          } else if (this.isNonAllowancePromo) {
            fields = ['promotion_price', 'lump_sum_allowance']
          }
          return fields
        default:
          return fields
      }
    },
    existingPromo () {
      return (this.$route.query.id)
    },
    isComplexPromo () {
      return ['BUY_X_GET_X', 'BUY_X_SAVE_$Y'].includes(this.promo_type_constant)
    },
    promoPriceRules () {
      return (this.isComplexPromo || this.isAccrualPromo)
        ? [this.isValidPrice]
        : [this.isValidRequiredPrice]
    },
    categoryManagers () {
      return this.$store.getters.categoryManagers
    }
  },
  methods: {
    async validate () {
      const validFields = this.$refs.form.validate()
      if (this.existingPromo && !this.validPromoLevel) {
        const errors = await this.validateGroupLevel()
        if (errors.length > 0) {
          this.emitAlert(true, 'warning', null, errors)
          return false
        }
      }
      if (!validFields) {
        this.emitAlert(true, 'warning', 'Please check your form for errors')
        return false
      }
      return true
    },
    async validateGroupLevel () {
      let validationErrors = []
      const promoId = this.$route.query.id
      try {
        const res = await PromoItemGroup.getItemGroupsByPromo(promoId)
        if (res?.data?.length > 0) {
          const scanErr = res.data.some(group => !this.hasScanAllowance(group))
          const billbackErr = res.data.some(group => !this.hasBillingAllowance(group))
          if (scanErr && !this.validScanPromo) {
            this.scanError = true
            validationErrors.push("Scan allowance is required.")
          }
          if (billbackErr && !this.validBillbackPromo) {
            this.billbackError = true
            validationErrors.push(this.billbackErrorMessage)
          }
        }
        return validationErrors
      } catch (err) {
        console.error(err)
        return [err?.response?.data?.message || err.message]
      }
    },
    resetErrors () {
      if (this.scanError) this.scanError = false
      if (this.billbackError) this.billbackError = false
    }
  }
}
</script>
<style scoped>
.promoRow {
  height: 60px;
}
</style>