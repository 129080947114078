import Search from '@/axios/search-endpoint'
import { displayAlert } from './alert'

export const partySearch = {
  mixins: [displayAlert],
  methods: {
    async getBillingPartyDetails(parties) {
      const partyIds = parties.map(party => party.party_id)
      let data = null

      try {
        const searchTerms = this.putPartyIdsInGroups(partyIds)
        data = await this.batchIdSearch(searchTerms, "or")
      }
      catch (err) {
        this.handleError(err)
      }

      if (data?.length > 0) {
        parties = parties.map(party => {
          const result = data.find(p => p.party_id === party.party_id)
          if (result) {
            party.name = result.party_name
            party.vendor_id = result?.attributes?.VENDOR_ID || ''
            party.display_name = `${party.vendor_id} ${party.name}`
          }
          return party
        })
      }
      return parties
    },

    async getPartyDetailsFromIds(partyIds) {
      let data = null
      const parties = [];

      try {
        const searchTerms = this.putPartyIdsInGroups(partyIds)
        data = await this.batchIdSearch(searchTerms, "or")
      }
      catch (err) {
        this.handleError(err)
      }

      if (data?.length > 0) {
        partyIds.forEach(id => {
          let party = {};
          const result = data.find(p => p.party_id === id)
          if (result) {
            party.name = result.party_name
            party.vendor_id = result?.attributes?.VENDOR_ID || ''
            party.display_name = `${party.vendor_id} ${party.name}`
          }
          parties.push(party);
        })
      }
      return parties
    },

    async batchIdSearch(searchTerms, operator = "or") {
      try {
        const searches = searchTerms.map(searchTerm => {
          return Search.customerSearch(searchTerm, operator)
        })
        const { fulfilled, rejected } = await this.getAllSettled(searches, true)
        if (rejected.length > 0)
          throw rejected
        const results = fulfilled.flatMap(result => result.data)
        return results
      }
      catch (err) {
        this.handleError(err)
      }
    },
    putPartyIdsInGroups(idList) {
      const maxSearchSize = 250   // Will fail if above 275
      let searchTerms = new Array()
      // If just passing in one string value (usually a default value), then return an array of just that value
      if (typeof (idList) == "string")
        return [idList]
      // Else, break up the array into smaller batches and pass that back
      let groups = Math.ceil(idList.length / maxSearchSize)
      for (let x = 0; x < groups; x++) {
        let startIndex = x * maxSearchSize
        let endIndex = (x + 1) * maxSearchSize
        searchTerms.push(idList.slice(startIndex, endIndex).join(' '))
      }
      return searchTerms
    },
  }
}