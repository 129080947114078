<template>
  <v-form ref="form" lazy-validation class="ma-0 pa-0">
  <v-row class="ma-0 pa-0">
    <v-col class="px-1">
      <v-menu
        v-model="start_dt_picker"
        transition="scale-transition"
        offset-y
        :nudge-top="hideDetails ? null : '25'"
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="promo.start_dt"
            label="Start Date"
            ref="start_dt"
            type="date"
            prepend-inner-icon="mdi-calendar"
            dense
            background-color="#fff"
            outlined
            :readonly="readOnlyPromo"
            :hide-details="hideDetails"
            v-bind="attrs"
            v-on="!readOnlyPromo ? on : null"
            :rules="[isValidStartDate]"
            @input="promo.end_dt ? $refs.end_dt.validate() : null"
            :error="startDateErr">
          </v-text-field>
        </template>
        <v-date-picker 
          v-model="promo.start_dt"
          :max="promo.end_dt"
          :allowed-dates="allowedStartDates"
          no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col class="px-1">
      <v-menu
        v-model="end_dt_picker"
        transition="scale-transition"
        offset-y
        :nudge-top="hideDetails ? null : '25'"
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="promo.end_dt"
            label="End Date"
            ref="end_dt"
            type="date"
            prepend-inner-icon="mdi-calendar"
            dense
            background-color="#fff"
            outlined
            :readonly="readOnlyPromo"
            :hide-details="hideDetails"
            v-on="!readOnlyPromo ? on : null"
            v-bind="attrs"
            :rules="[isValidEndDate]"
            @input="promo.start_dt ? $refs.start_dt.validate() : null">
          </v-text-field>
        </template>
        <v-date-picker 
          v-model="promo.end_dt"
          :min="promo.start_dt"
          :show-current="promo.start_dt || true"
          :allowed-dates="allowedEndDates"
          no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
  </v-form>
</template>
<script>
import PartyAttributeValue from '@/axios/party-attribute-value-endpoint'
// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
// Third-Party Helpers
import { addMinutes } from 'date-fns'
export default {
  data () {
    return {
      start_dt_picker: false,
      end_dt_picker: false,
      wholesalerStartDay: -1,
      promoLengthRestrictions: {
        'pricechopper': {
          '1-2 Day Ad - Billback': {
            minDays: 1,
            maxDays: 2
          },
          '7 Day Ad - Billback': {
            minDays: 3
          },
          'Special 4 Week': {
            minDays: 14,
            maxDays: 14
          }
        }
      }
    }
  },
  name: 'PromoDates',
  mixins: [displayAlert, userAccess],
  props: {
    promo: Object,
    readOnlyPromo: {
      type: Boolean,
      default: true
    },
    hideDetails: [Boolean, String]
  },
  // temporarily disabled per JL request 4/28
    /*
    wholesalerStartDay: {
      handler (newValue) {
        if (newValue !== undefined && newValue !== null) {
          this.checkPromoStartDate()
        }
      }
    },
  */
  async created () {
    if (this.promo_categories.length === 0) {
      await this.$store.dispatch('getPromoCategories')
    }
  },
  computed: {
    promoCategories () {
      return this.userPromoCategories.map(c => c.name)
    },
    dateRestrictedCategories() {
      const tenantConfig = {
        'awg':
          this.promoCategories.filter(c => {
            return ![
              'Ad Billback',
              'Ad Scan',
              'Ad Scan Required Retail',
              'VPAP Hot Buy Billback',
              'VPAP Hot Buy Scan Required Retail',
              'Hot Buy Scan',
              'AMAP',
              'Early Buy Allowance'
            ].includes(c)
          }),
        'pricechopper': [
          'EDLC Billback',
          'EDLC Scan',
          'TPR Scan',
          'TPR Billback',
          'DSD EDLC Scan',
          'DSD TPR Scan'
        ],
        'alliance-retail-group': [
          'Ad Scan',
          'Ad Billback',
          'TPR Scan',
          'TPR Billback'
        ]
      }
      return tenantConfig[this.$auth.tenant] || []
    },
    categoryName () {
      return this.promo?.promo_category?.name
    },
    dateRules() {
      if (this.$auth.tenant === 'awg') { 
        return  { startDay: 1, endDay: 0 }
      }
      else if (this.$auth.tenant === 'pricechopper') { 
        return  { startDay: 0, endDay: 6 }
      }
      else if (this.$auth.tenant === 'alliance-retail-group') { 
        if (this.categoryName === 'TPR Scan' || this.categoryName === 'TPR Billback') {
          return { startDay: 0, endDay: 6 }
        } else {
          return { startDay:1, endDay: null}
        }
      }
      return null
    },
    isDateRestrictedPromo () {
      return this.dateRestrictedCategories.includes(this.categoryName)
    },
    applyDateRestrictions () {
      if (this.categoryName && this.promoCategories?.length > 0) {
        if (this.readOnlyPromo) return false

        if (this.$auth.tenant === 'awg' && !this.isDateRestrictedPromo) {
          return (this.categoryName === 'AMAP' ? false : this.limitAccessByRelatedVendors)
        }
        return this.isDateRestrictedPromo
      }
      return false
    },
    applyPromoLengthRestriction () {
      return Boolean(this.promoLengthRestrictions[this.$auth.tenant]?.[this.categoryName])
    },
    allowedStartDates () {
      // return (this.promo.override_store_offset === true && this.wholesalerStartDay >= 0) ? this.isValidWholesalerStartDate : null
      return this.applyDateRestrictions ? this.isAllowedStartDate : null
    },
    allowedEndDates () {
      return this.applyDateRestrictions || this.applyPromoLengthRestriction ? this.isAllowedEndDate : null
    },
    startDateErr () {
      // temporarily disabled per JL request 4/28
      /*
      if (this.promo.override_store_offset && this.promo.start_dt) {
        return (this.checkPromoStartDate() === false)
      }
      */
      return false
    }
  },
  methods: {
    validateForm () {
      return this.$refs.form.validate()
    },
    validate () {
      const validFields = this.validateForm()
      if (!validFields) {
        this.emitAlert(true, 'warning', 'Please check your form for errors')
        return false
      }
      return true
    },
    isAllowedStartDate (val) {
      const numericDay = this.getNumericDay(val)
      return numericDay === this.dateRules.startDay
    },
    isAllowedEndDate (val) {
      let allowed = true
      const numericDay = this.getNumericDay(val)
      if (this.applyDateRestrictions && this.dateRules.endDay != null ) {
        allowed = numericDay === this.dateRules.endDay
      }
      if (this.applyPromoLengthRestriction && this.promo.start_dt) {
        allowed = this.validatePromoDateLength(this.promo.start_dt, val)
      }
      return allowed
    },
    getNumericDay (value) {
      let date = new Date(value)
      date = addMinutes(date, date.getTimezoneOffset())
      return date.getDay()
    },
    getTimezoneOffset (value) {
      let date = new Date(value)
      return addMinutes(date, date.getTimezoneOffset())
    },
    isValidStartDate (value) {
      if (value) {
        if (this.applyDateRestrictions && !this.isAllowedStartDate(value)) {
          return 'Invalid weekday'
        }
        if (!this.isValidYear(value)) {
          this.start_dt_picker = false
          return 'Invalid year value'
        }
        if (this.promo.end_dt) {
          const validDates = this.validatePromoDateRange()
          if (!validDates) {
            this.start_dt_picker = false
            return 'Invalid date range'
          }
        }
        return true
      }
      return 'Start Date is required'
    },
    isValidEndDate (value) {
      if (value) {
        if (this.applyDateRestrictions && !this.isAllowedEndDate(value)) {
          return 'Invalid weekday'
        }
        if (!this.isValidYear(value)) {
          this.end_dt_picker = false
          return 'Invalid year value'
        }
        if (this.promo.start_dt) {
          const validDates = this.validatePromoDateRange()
          if (!validDates) {
            this.end_dt_picker = false
            return 'Invalid date range'
          }
          this.$refs.start_dt.validate()
        }
        return true
      }
      return 'End Date is required'
    },
    isValidYear (value) {
      if (value) {
        let [year] = value.split('-')
        year = Number(year)
        const currentYear = new Date().getFullYear()
        return (year > currentYear - 3) && (year < currentYear + 3)
      }
      return false
    },
    validatePromoDateRange () {
      let valid = true
      const { start_dt, end_dt } = this.promo
      const startDate = new Date(start_dt)
      const endDate = new Date(end_dt)
      valid = (endDate >= startDate)
      if (this.promoLengthRestrictions[this.$auth.tenant]?.[this.categoryName]) {
        valid = this.validatePromoDateLength(startDate, endDate)
      }
      return valid
    },
    validatePromoDateLength (start, end) {
      let valid = true
      const promoLength = ((this.getTimezoneOffset(end) - this.getTimezoneOffset(start)) / (1000 * 3600 * 24)) + 1
      const { minDays, maxDays } = this.promoLengthRestrictions[this.$auth.tenant][this.categoryName]
      if (minDays) {
        valid = promoLength < minDays ? false : valid
      }
      if (maxDays) {
        valid = promoLength > maxDays ? false : valid
      }
      return valid
    },
    checkPromoStartDate () {
      // temporarily disabled per JL request 4/28
      /*
      if (this.promo.override_store_offset && this.wholesalerStartDay === -1 && this.promo.responsible_party_id) {
        return this.getWholesalerStart(this.promo.responsible_party_id)
      }
      if (this.allowedStartDates !== null && this.promo.start_dt) {
        if (this.isValidWholesalerStartDate(this.promo.start_dt) === false) {
          this.emitAlert(true, 'warning', 'Promo start date value is invalid for the overriding responsible party. Please select a new start date.', [], false)
          return false
        }
      }
      */
      return true
    },
    async getWholesalerStart (partyId) {
      try {
        if (!partyId) return
        const res = await PartyAttributeValue.getAttributeValuesByParty(partyId)

        if (res.data.length > 0) {
          const wholesalerStartAttr = res.data.find(result => result.attr && result.attr.id === 'WHOLESALER_PROMO_START')
          if (wholesalerStartAttr && wholesalerStartAttr.attr_key_value.constant) {
            // constant = numeric day value
            const dayValue = Number(wholesalerStartAttr.attr_key_value.constant)
            // our api assigns 7 to Sunday but Date.prototype.getDay 
            // thinks Sunday is 0, so update value if necessary
            this.wholesalerStartDay = (dayValue === 7) ? 0 : dayValue
          }
        }
      } catch (err) {
        this.handleError(err)
      }
    }
  }
}
</script>